import React, { useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Col, } from "reactstrap"
import { Link, SocialIcons } from ".."
import isoLanguage from "../../data/languages"
import FMMLogoMobile from "../../images/guiasexoseguro-logo-mobile.svg"
import FMMLogo from "../../images/guiasexoseguro-logo.svg"
import HamburgerCloseIcon from "../../images/hamburger-close-icon.svg"
import HamburgerIcon from "../../images/hamburger-icon.svg"
import "./Header.scss"
import HeaderMenu from "./HeaderMenu"
import HrefLangs from "./HrefLangs"

const Header = ({ lang, languages, mobileTrigger, slug }) => {
  const [languageModal, setLanguageModal] = useState(false)
  const [menuModal, setMenuModal] = useState(false)
  const [selectedLanguageName, setSelectedLanguageName] = useState(lang)
  const [pageLanguages, setPageLanguages] = useState([])

  useEffect(() => {
    mobileTrigger(menuModal)
  }, [menuModal, mobileTrigger])

  const createPageLanguages = useCallback(() => {
    const langArr = []

    isoLanguage?.forEach(lang => {
      const langInArray = languages?.find(
        fmmLang => fmmLang?.node?.fmmCore?.languageCode === lang?.code
      )

      if (langInArray) {
        langArr.push({
          name: lang?.name,
          code: lang?.code,
        })
      }
    })

    setPageLanguages(langArr)
  }, [languages])

  useEffect(() => {
    const languageName = pageLanguages?.find(
      language => language.code === lang
    )?.name
    setSelectedLanguageName(languageName)
  }, [lang, pageLanguages])

  useEffect(() => {
    createPageLanguages()
  }, [languages, createPageLanguages])

  const toggleLanguageModal = () => setLanguageModal(!languageModal)
  const toggleMenuModal = () => setMenuModal(!menuModal)

  return (
    <>
      <Helmet>
        <meta name="facebook-domain-verification" content="5ek1kjuz6ciblskgfkjc2uxenp2re3" />
      </Helmet>
      <HrefLangs languages={languages} slug={slug} lang={lang} />
      
      <header
        id="header"
        className={`${menuModal ? "mobile-menu-active" : ""} ${lang}`}
      >
        <section className="container">
          <section className="row">
            <Col xs="6" md="3" className="d-flex align-items-center">
              <Link to="/" lang={lang}>
                <picture>
                  <source
                    media="(max-width: 767px)"
                    srcSet={FMMLogoMobile}
                    height="40px"
                    width="120px"
                  />
                  <img
                    src={FMMLogo}
                    alt="Guía de Sexo Seguro "
                    height="60"
                    width="192"
                  />
                </picture>
              </Link>
            </Col>

            <Col xs="6" md="9" className="d-flex d-lg-none align-items-center">
              <section className="ml-auto">
                {/* <button className="icon-button" onClick={toggleLanguageModal}>
                  <img
                    src={!menuModal ? LanguageIcon : LanguageIconColored}
                    height="22px"
                    width="22px"
                    alt=""
                  />
                </button> */}

                <button className="icon-button ml-3" onClick={toggleMenuModal}>
                  <img
                    src={!menuModal ? HamburgerIcon : HamburgerCloseIcon}
                    height="40"
                    width="40"
                    alt=""
                  />
                </button>
              </section>
            </Col>
          </section>
          <section className="row">
          <nav className="nav main-menu d-none d-lg-flex">
              <HeaderMenu
                lang={lang}
                toggleLanguageModal={toggleLanguageModal}
                selectedLanguageName={selectedLanguageName}
              />
            </nav>
          </section>
        </section>
      </header>

      <section className={`mobile-menu ${menuModal ? "active" : ""}`}>
        <nav className="menu">
          <HeaderMenu
            lang={lang}
            toggleLanguageModal={toggleLanguageModal}
            selectedLanguageName={selectedLanguageName}
          />
        </nav>

        <section className="mt-auto">
          <SocialIcons inverted />
        </section>
      </section>
    </>
  )
}

export default Header