import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from '@reach/router'

const HrefLangs = ({ languages, slug = '/', lang }) => {
  const { pathname } = useLocation();
  
  const {
     site: {
      siteMetadata: { siteUrl, default_lang },
    },
    } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            default_lang
          }
        }
      }
    `
  );

  const canonicalPath = `https://findmymethod.org/es${pathname}`;
  const urlPath = `${siteUrl}${pathname}`;
 
  
  return (
    <Helmet>
      <link rel="canonical" href={`${canonicalPath}`} />
      <link rel="alternate" hreflang="x-default" href={urlPath} />
    </Helmet>
  )
}

export default HrefLangs
