import { graphql, useStaticQuery } from "gatsby"
import React, { Fragment, useEffect, useState } from "react"
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { CountriesGrid, Link } from ".."
import useCountriesInLanguage from "../../hooks/useCountriesInLanguage"
//import parse from 'html-react-parser';

const HeaderMenu = ({ lang }) => {
  const [langMenus, setLangMenus] = useState([])
  const countriesInLanguage = useCountriesInLanguage(lang)

  const { header } = useStaticQuery(graphql`
    query {
      header: allWpFmmMenu(
        sort: { fields: fmm_menu___order }
        filter: { fmm_menu: { position: { eq: "header" } } }
      ) {
        edges {
          node {
            fmm_menu {
              dropdown
              order
              parentOrderId
              position
            }
            title
            fmmCore {
              languageCode
              frontendSlug
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    const menusList = header?.edges?.filter(
      item => item?.node?.fmmCore?.languageCode === lang
    )

    setLangMenus(menusList)
  }, [lang, header])
  const filterTools = header?.edges?.filter(
    item => item?.node?.fmm_menu?.parentOrderId === 1 
  )
  const toolsOnLang = filterTools.filter(
    item => item?.node?.fmmCore?.languageCode === lang
  )
  const filterPregnant = header?.edges?.filter(
    item => item?.node?.fmm_menu?.parentOrderId === 4 
  )
  const pregnatOnLang = filterPregnant.filter(
    item => item?.node?.fmmCore?.languageCode === lang
  )
  const filterFAQ = header?.edges?.filter(
    item => item?.node?.fmm_menu?.parentOrderId === 6 
  )
  const faqOnLang = filterFAQ.filter(
    item => item?.node?.fmmCore?.languageCode === lang
  )
  const parentMenu = langMenus.filter(
    item => item?.node?.fmm_menu?.parentOrderId === null
  )
  console.log(parentMenu)

  return (
    <>
      {parentMenu[0]?.node?.fmmCore?.frontendSlug &&  (
        <Link
          to={parentMenu[0]?.node?.fmmCore?.frontendSlug}
          lang={lang}
          activeClassName="active"
        >
          <span className="menu-parent"
            dangerouslySetInnerHTML={{ __html: parentMenu[0]?.node?.title }}
          />
        </Link>
      )}
      
      {toolsOnLang.length >= 1 && (
        <UncontrolledDropdown>
        <DropdownToggle className="button--link">
          <span className="menu-parent"
            dangerouslySetInnerHTML={{ __html: parentMenu[1]?.node?.title }}
          />
        </DropdownToggle>

        <DropdownMenu className="dropdown-style tools-dropdown">
          <ul className={`tools-list ${lang}`}>
            {toolsOnLang.map((item, index) => (
              <Fragment key={index}>
                <li>
                  <Link lang={lang} to={`${item?.node?.fmmCore?.frontendSlug}`}>
                    <span>{item.node?.title}</span>
                  </Link>
                </li>
              </Fragment>
            ))}
          </ul>

        </DropdownMenu>
      </UncontrolledDropdown>
    )}

      {countriesInLanguage.length >= 1 && (
        <UncontrolledDropdown>
          <DropdownToggle className="button--link">
            <span className="menu-parent"
              dangerouslySetInnerHTML={{ __html: parentMenu[2]?.node?.title }}
            />
          </DropdownToggle>

          <DropdownMenu className="country-dropdown">
            <CountriesGrid lang={lang} />
          </DropdownMenu>
        </UncontrolledDropdown>
      )}

      {parentMenu[3]?.node?.fmmCore?.frontendSlug && (
        <Link
          to={parentMenu[3]?.node?.fmmCore?.frontendSlug}
          activeClassName="active" target="_blank"
        >
          <span className="menu-parent"
            dangerouslySetInnerHTML={{ __html: parentMenu[3]?.node?.title }}
          />
        </Link>
      )}

      {pregnatOnLang.length >= 1 && (
        <UncontrolledDropdown>
        <DropdownToggle className="button--link">
          <span className="menu-parent"
            dangerouslySetInnerHTML={{ __html: parentMenu[4]?.node?.title }}
          />
        </DropdownToggle>

        <DropdownMenu className="dropdown-style tools-dropdown">
          <ul className={`tools-list ${lang}`}>
            {pregnatOnLang.map((item, index) => (
              <Fragment key={index}>
                <li>
                  <Link lang={lang} to={`${item?.node?.fmmCore?.frontendSlug}`}>
                    <span>{item.node?.title}</span>
                  </Link>
                </li>
              </Fragment>
            ))}
          </ul>

        </DropdownMenu>
      </UncontrolledDropdown>
    )}

      {parentMenu[5]?.node?.fmmCore?.frontendSlug &&  (
        <Link
          to={parentMenu[5]?.node?.fmmCore?.frontendSlug}
          lang={lang}
          activeClassName="active"
        >
          <span className="menu-parent"
            dangerouslySetInnerHTML={{ __html: parentMenu[5]?.node?.title }}
          />
        </Link>
      )}
      
      {faqOnLang.length >= 1 && (
        <UncontrolledDropdown>
        <DropdownToggle className="button--link">
          <span className="menu-parent"
            dangerouslySetInnerHTML={{ __html: parentMenu[6]?.node?.title }}
          />
        </DropdownToggle>

        <DropdownMenu className="dropdown-style faq-dropdown">
          <ul className={`faq-list ${lang}`}>
            {faqOnLang.map((item, index) => (
              <Fragment key={index}>
                <li>
                  <Link lang={lang} to={`${item?.node?.fmmCore?.frontendSlug}`}>
                    <span>{item.node?.title}</span>
                  </Link>
                </li>
              </Fragment>
            ))}
          </ul>

        </DropdownMenu>
      </UncontrolledDropdown>
    )}
    </>
  )
}

export default HeaderMenu
